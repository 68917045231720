
import { defineComponent, useStore, useContext, onMounted, ref, watch, onUnmounted, computed } from '@nuxtjs/composition-api'
import VekeCarousel from '~/components/veke-carousel/VekeCarousel.vue'
import ProductTile from '~/components/category/ProductTile.vue'
import type { ProductTileAlgolia } from '~/types/product'
import { SwatchActions } from '~/store/swatch'
import { attributesForProductTile } from '~/helpers/instantsearch'
import { useIntersection } from '~/composable/useIntersection'
import { useProduct } from '~/composable/useProduct'
import { UiGetters, UiMutations } from '~/store/ui'
export default defineComponent({
  components: { VekeCarousel, ProductTile },
  props: {
    carouselName: {
      type: String,
      required: false,
      default: ''
    },
    perPageCustom: {
      type: Array,
      required: false,
      default: () => [[320, 2], [768, 3], [1024, 4]]
    },
    slides: {
      type: Number,
      default: 12
    },
    categoryIds: {
      type: Array,
      required: false,
      default: null
    },
    parentSkus: {
      type: Array,
      required: false,
      default: null
    },
    skus: {
      type: Array,
      required: false,
      default: null
    },
    mainVariationsOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    productSearchQuery: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props) {
    const { $algolia, $matomo, $gtm } = useContext()
    const { categoyListItemsForGtm } = useProduct()
    const store = useStore()

    const products = ref<ProductTileAlgolia[]>([])
    const productCarousel = ref(null)
    const itemListData = { itemListId: props.carouselName, itemListName: props.carouselName }

    const showInDom = computed(() => store.getters[UiGetters.getSlidesInDom])

    // @ts-ignore
    const { isIntersecting, startIntersecting, stopIntersecting } = useIntersection(productCarousel.value)

    // Default values for props
    const perPageCustomHandler = props.perPageCustom || [[320, 2], [768, 3], [1024, 4]]
    const slides = props.slides || 12
    const productSearchQuery = props.productSearchQuery || ''
    const sendViewListEvent = (items: any, itemListData: { itemListName: string, itemListId: string }) => {
      $gtm.push({
        event: 'view_item_list',
        ecommerce: {
          item_list_id: itemListData.itemListId,
          item_list_name: itemListData.itemListName,
          items
        }
      })
    }

    const sendViewListEventHelper = (payload: { algoliaFirstIndex: number, itemAmount: number }) => {
      const algoliaItems = products.value.slice(payload.algoliaFirstIndex, payload.algoliaFirstIndex + payload.itemAmount)
      // Log the carousel index values or do whatever processing you need
      const items = categoyListItemsForGtm(algoliaItems, store, itemListData)
      sendViewListEvent(items, itemListData)
    }

    // Fetch swatches on mounted
    onMounted(() => {
      store.commit(UiMutations.setScreenSize, window.innerWidth)
      watch(productCarousel, (elementFound) => {
        if (elementFound) {
          // @ts-ignore
          startIntersecting(productCarousel.value)
          watch(isIntersecting, (carouselIsInView) => {
            if (carouselIsInView && products.value.length > 0) {
              const itemListData = { itemListId: props.carouselName, itemListName: props.carouselName }
              const items = categoyListItemsForGtm([...products.value].splice(0, showInDom.value), store, itemListData)
              sendViewListEvent(items, itemListData)
            }
          })
        }
      })
      store.dispatch(SwatchActions.fetchSwatchesByType, 'Väri')
    })

    onUnmounted(() => {
      if (productCarousel.value) {
        stopIntersecting(productCarousel.value as unknown as Element)
      }
    })

    // Search products using Algolia when the component is created
    const searchProducts = () => {
      let filters = productSearchQuery || null

      if (!filters) {
        let orFilters: string[] = []
        const andFilters: string[] = []

        if (props.parentSkus) {
          orFilters = orFilters.concat(props.parentSkus.map(sku => `parentSku:'${sku}'`))
        }
        if (props.skus) {
          orFilters = orFilters.concat(props.skus.map(sku => `sku:'${sku}'`))
        }
        if (props.categoryIds) {
          orFilters = orFilters.concat(props.categoryIds.map(id => `categories.id:${id}`))
        }
        if (props.mainVariationsOnly) {
          andFilters.push('isMainVariation:true')
        }

        filters = [orFilters.join(' OR '), andFilters.join(' AND ')].filter(Boolean).map(fs => `(${fs})`).join(' AND ')
      }

      if (filters !== '') {
        // Call Algolia's search API
        $algolia().search('', {
          filters,
          attributesToHighlight: [],
          attributesToRetrieve: attributesForProductTile,
          hitsPerPage: slides
        }).then(({ hits }: { hits: ProductTileAlgolia[] }) => {
          products.value = hits
        })
      }
    }

    // Call the search function when the component is created
    searchProducts()

    // Handle event click
    const handleEvent = (index: number) => {
      $matomo.trackEvent('ProductTileCarousel', 'product-tile-carousel-click', 'carousel-index', index)
    }
    return {
      handleEvent,
      perPageCustomHandler,
      products,
      productCarousel,
      sendViewListEventHelper,
      itemListData
    }
  }
})
