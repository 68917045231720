
import Vue from 'vue'
import ReviewStars from '~/components/product/ReviewStars.vue'
import StockStatus from '~/components/product/StockStatus.vue'
import type { GtmItemListData, ProductAlgolia, ProductTileAlgolia } from '~/types/product'
import ProductPriceLabel from '~/components/product/ProductPriceLabel.vue'
import useProductMixing from '~/mixins/useProduct'
import { SwatchGetters } from '~/store/swatch'
import ProductLabels from '~/components/product/ProductLabels.vue'
import type { SwatchNameWithStyles } from '~/types/swatch'
import { UiGetters } from '~/store/ui'
import { useProduct } from '~/composable/useProduct'
export default Vue.extend({
  name: 'ProductTile',
  components: {
    ProductPriceLabel,
    ProductLabels,
    ReviewStars,
    StockStatus
  },
  mixins: [useProductMixing],
  props: {
    product: {
      type: Object as () => ProductTileAlgolia|ProductAlgolia,
      required: true
    },
    itemListData: {
      type: Object as () => GtmItemListData,
      required: false,
      default: null
    }
  },
  data () {
    return {
      maxColors: 5
    }
  },
  computed: {
    milieuImage () {
      return this.$store.getters[UiGetters.isMilieuImageShown]
    },
    colors (): string[] {
      return this.product?.colorVariations?.filter(Boolean) || []
    },
    visibleColors (): SwatchNameWithStyles[] {
      const qty = (this.colors.length > this.maxColors) ? this.maxColors - 1 : this.maxColors
      const visibleColorNames = this.colors.slice(0, qty)
      return this.$store.getters[SwatchGetters.getManySwatchStyles]('Väri', visibleColorNames)
    },
    colorsLeftQty (): number {
      return (this.colors.length > this.maxColors) ? this.colors.length - (this.maxColors - 1) : 0
    },
    reviewScore (): number {
      return this.product.yotpo?.reviewScore || 0
    },
    reviewAmount (): number {
      return this.product.yotpo?.reviewAmount || 0
    }
  },
  methods: {
    openProductPage () {
      if (this.itemListData) {
        const { categoyListItemsForGtm } = useProduct()
        const items = categoyListItemsForGtm([this.product], this.$store, this.itemListData)
        this.sendGtmEventHelper(items, this.itemListData)
      }
    },
    sendGtmEventHelper (items: any[], itemListData: GtmItemListData) {
      this.$gtm.push({
        event: 'select_item',
        ecommerce: {
          item_list_id: itemListData.itemListId,
          item_list_name: itemListData.itemListName,
          items
        }
      })
    }
  }
})
